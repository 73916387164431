import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const MentionsPage = () => {

  return (
    <Layout>
      <SEO title="Mentions légales" />
      <section className="py-16 md:py-36 xl:py-40 2xl:py-40">
        <div className="main-container">
          <h1 className="display-2">
            Mentions légales
          </h1>
          <h2 className="font-medium text-lg">
            Propriétaire et éditeur du site:
          </h2>
          <p className="text-lg">
            BELLADRINKS Capital 5 000 € <br/>
            84 chemin du grand revoyet 69230 st Genis Laval <br/>
            Siret 81396167900012
          </p>
          <h2 className="font-medium text-lg">
            Réalisation du site internet:
          </h2>
          <p className="text-lg">
            Quentin Mayet
          </p>
          <h2 className="font-medium text-lg">
            Hébergement:
          </h2>
          <p>
            Netlify <br/>
            610 22nd Street, Suite 315,<br/>
            San Francisco, CA 94107<br/>
          </p>
          <h2 className="font-medium text-lg">
            Crédit photo
          </h2>
          <p>Margot Raymond</p>
          <h2 className="font-medium text-lg">
            Politique de confidentialité / protection des données
          </h2>
          <p className="text-lg">
            Les données personnelles telles que nom, adresse, numéro de téléphone ou adresse E-mail, mises à notre disposition dans le cadre de nos activités Internet sont enregistrées et gérées conformément aux lois nationales de protection et de sécurité des données. Vous décidez si vous souhaitez ou non nous communiquer vos données personnelles par ex. dans le cadre d’une demande de devis ou de n’importe quelle autre opération. Nous utilisons vos données personnelles pour répondre à votre demande de devis, vous faire parvenir une offre ou des informations spéciales.
            Vous pouvez demander à tout moment par E-mail que les données enregistrées soient modifiées, supprimées ou bloquées. Nous ne revendons pas vos données personnelles à des tierces personnes et ne les commercialisons d’aucune manière. Lorsque vous accédez à notre site Internet, certaines informations (telles que par ex. navigateur Internet et système d’exploitation, nom du domaine d’où vous venez, nombre de visites, durée moyenne de visite, pages consultées) sont automatiquement retenues pour nos statistiques Internet, ces sont exploitées sous forme anonyme. Par ailleurs, nous n’utilisons ni cookies, ni contenus actifs. Nous utilisons uniquement ces informations pour analyser l’attractivité de notre site Internet et en améliorer le contenu.
          </p>
        </div>
      </section>
    </Layout>
  )
}

export default MentionsPage

